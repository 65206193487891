import React from 'react';

import { useProducts } from '@hooks/useProducts';

import ProductsBlock from '@components/Blocks/ProductsBlock';
import Layout from '@components/Layouts/Layout';
import InformationBanner from '@components/Blocks/InformationBanner';

import SEO from '@components/Layouts/SEO';
import { YandexTableau } from '@components/Layouts/YandexTableau';

export function Head() {
  return (
    <>
      <SEO
        title="Заказать блокнот с необычным дизайном с доставкой"
        description="Закажите необычный блокнот для повседневного использования или особые блокноты для саморефлексии, семейных историй и другого на сайте Периодики!"
      />
      <YandexTableau />
    </>
  );
}

function ProductsPage() {
  const { notebookProducts } = useProducts();

  return (
    <Layout>
      <InformationBanner
        banner={{
          title: 'Блокноты',
          description: `<p>
          Откройте для себя новую главу — ежедневники для внимательных к жизни людей. В линейке блокнотов Периодики три формата:
      </p>
      <br/>
      <p>
      «Один момент». Блокнот для ценителей настоящего и тех, кто хочет ими стать. Записывайте важные события, мысли и чувства, чтобы всегда помнить: особенные моменты создаем мы сами.
      </p>
      <br/>
      <p>«55 семейных историй». Блокнот для сохранения ценных историй вашей семьи. Запишите воспоминания родных, которые объединяют поколения, чтобы передать их дальше.</p>
      <br/>
      <p>Блокнот в точку с кастомной обложкой. Создайте свой идеальный ежедневник, который подойдет для  регулярных заметок, личного дневника, работы или учебы. </p>
      </p>
      `,
          image: 'notebooks.jpg',
          textColor: 'white',
        }}
        twoCols
      />

      <ProductsBlock products={notebookProducts} />
    </Layout>
  );
}

export default ProductsPage;
